<template>
  <select
    :value="value"
    @input="onChange($event)"
    :class="[
      className,
      'FormSelect h-10 border inline-flex px-4 appearance-none focus:outline-none rounded-none text-sm',
    ]"
    :style="[
      (value === undefined || value === null) && {
        color: '#999',
      },
    ]"
  >
    <!-- 기본값으로 빈 문자열을 사용하면 선택된다 -->
    <option v-if="usePlaceholder" :value="''" disabled>
      {{ placeholder || '선택해주세요.' }}
    </option>

    <option v-for="item in options" :key="item.value" :value="item.value">
      {{ item.label }}
    </option>
  </select>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Select',
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    options: Array as PropType<Array<{ label: string; value: string }>>,
    onChange: Function as PropType<(value: string) => void>,
    className: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    usePlaceholder: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
});
</script>

<style lang="scss">
select.FormSelect {
  background: url('../assets/ico_selectbar_arrow.png') no-repeat;
  background-size: 10px;
  background-position: calc(100% - 14px) center;
}
</style>
