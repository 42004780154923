export default function useEnableBidOptions() {
  const enableBidOptions = [
    {
      label: '전체',
      value: '',
    },
    {
      label: 'Bid Now',
      value: true,
    },
    {
      label: 'Buy Now Only',
      value: false,
    },
  ];

  return {
    enableBidOptions,
  };
}
