
import Select from '@/components/Select.vue';
import { defineComponent, PropType } from 'vue';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import useEnableBidOptions from '@/mixins/useEnableBidOptions';

export default defineComponent({
  name: 'ListFilter',
  components: {
    Select,
  },
  props: {
    params: {
      type: Object as PropType<any>, // TODO:
      required: true,
    },
    handleChangeFilterParams: { type: Function, required: true },
    onClickApply: { type: Function, required: true },
    onClickSearch: { type: Function, required: true },
    isFilterOn: Boolean,
  },
  methods: {},
  setup(props) {
    const { categoryDepth1Options } = useCategoryOptions();
    const { enableBidOptions } = useEnableBidOptions();
    const SettlementStatusOption = [
      { value: '', label: '전체' },
      { value: 'purchase_complete', label: '구매확정' },
      { value: 'waiting', label: '정산대기중' },
      { value: 'complete', label: '정산완료' },
      { value: 'hold', label: '정산보류' },
    ];
    const orderTypeOption = [
      { value: '', label: '전체' },
      { value: 'bid', label: 'Bid Now' },
      { value: 'buynow', label: 'Buy Now' },
    ];
    const updateParams = (key: any, value: any) => {
      props.handleChangeFilterParams(key, value);
    };

    return {
      updateParams,
      categoryDepth1Options,
      enableBidOptions,
      SettlementStatusOption,
      orderTypeOption,
    };
  },
});
