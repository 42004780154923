
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Select',
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    options: Array as PropType<Array<{ label: string; value: string }>>,
    onChange: Function as PropType<(value: string) => void>,
    className: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    usePlaceholder: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
});
