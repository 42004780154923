
import { computed, defineComponent} from 'vue';
import Close from '@/components/icons/Close.vue';
import convertNumWithComma from '@/utils/convertNumWithComma';

export default defineComponent({
  name: 'SettlementDetailModal',
  props: {
    isOpen: {
      type: Boolean,
    },
    settlementData: {
      type: Object,
    },
  },
  components: {
    Close,
  },
  setup(props, { emit }) {
    const input = computed(() => {
      return {
        reason: props.settlementData?.trackingCode,
      };
    });

    const handleSubmit = async () => {
      if (!input.value.reason) {
        alert('보류사유를 입력해주세요.');
        return;
      }
      emit('fetchDetailModal');
      onClose();
    };

    const onClose = () => {
      emit('onClose');
    };

    return {
      input,
      convertNumWithComma,
      handleSubmit,
      onClose,
    };
  },
});
