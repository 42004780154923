<template>
  <form @submit.prevent="onClickSearch">
    <div class="ListFilter">
      <div class="FilterItem">
        <label>정산기간 : {{ params.start }} 부터</label>
        <input
          type="date"
          id="start"
          :value="params.start"
          @input="updateParams('start', $event.target.value)"
        />
      </div>
      <div class="FilterItem">
        <label>정산기간 : {{ params.end }} 까지</label>
        <input
          type="date"
          id="end"
          :value="params.end"
          @input="updateParams('end', $event.target.value)"
        />
      </div>

      <div class="FilterItem">
        <label for="start">카테고리</label>
        <Select
          :value="params.categoryDepth1Id"
          :onChange="(e) => updateParams('categoryDepth1Id', e.target.value)"
          :options="categoryDepth1Options"
          :usePlaceholder="false"
        ></Select>
      </div>

      <div class="FilterItem">
        <label for="buyOption">구매방식</label>
        <Select
          :value="params.orderType"
          :onChange="(e) => updateParams('orderType', e.target.value)"
          :options="orderTypeOption"
          :usePlaceholder="false"
        ></Select>
      </div>

      <div class="FilterItem">
        <label for="option">판매옵션</label>
        <Select
          :value="params.enableBid"
          :onChange="(e) => updateParams('enableBid', e.target.value)"
          :options="enableBidOptions"
          :usePlaceholder="false"
        ></Select>
      </div>

      <div class="FilterItem">
        <label for="status">정산상태</label>
        <Select
          :value="params.settlementStatus"
          :onChange="(e) => updateParams('settlementStatus', e.target.value)"
          :options="SettlementStatusOption"
          :usePlaceholder="false"
        ></Select>
      </div>

      <button
        :class="['Filter__applyButton', isFilterOn && 'isApplied']"
        @click="onClickApply"
        type="button"
      >
        필터적용
      </button>
    </div>

    <div class="flex w-full mt-6">
      <input
        type="text"
        class="
          Filter__textInput
          border border-black
          flex-1
          h-10
          py-3
          px-10
          text-xs
        "
        placeholder="LOT No. 작가/브랜드명, 상품명으로 검색"
        :value="params.keyword"
        @change="(e) => updateParams('keyword', e.target.value)"
      />
      <button class="w-30 h-10 ml-4 bg-black text-white text-xs" type="submit">
        검색
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import Select from '@/components/Select.vue';
import { defineComponent, PropType } from 'vue';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import useEnableBidOptions from '@/mixins/useEnableBidOptions';

export default defineComponent({
  name: 'ListFilter',
  components: {
    Select,
  },
  props: {
    params: {
      type: Object as PropType<any>, // TODO:
      required: true,
    },
    handleChangeFilterParams: { type: Function, required: true },
    onClickApply: { type: Function, required: true },
    onClickSearch: { type: Function, required: true },
    isFilterOn: Boolean,
  },
  methods: {},
  setup(props) {
    const { categoryDepth1Options } = useCategoryOptions();
    const { enableBidOptions } = useEnableBidOptions();
    const SettlementStatusOption = [
      { value: '', label: '전체' },
      { value: 'purchase_complete', label: '구매확정' },
      { value: 'waiting', label: '정산대기중' },
      { value: 'complete', label: '정산완료' },
      { value: 'hold', label: '정산보류' },
    ];
    const orderTypeOption = [
      { value: '', label: '전체' },
      { value: 'bid', label: 'Bid Now' },
      { value: 'buynow', label: 'Buy Now' },
    ];
    const updateParams = (key: any, value: any) => {
      props.handleChangeFilterParams(key, value);
    };

    return {
      updateParams,
      categoryDepth1Options,
      enableBidOptions,
      SettlementStatusOption,
      orderTypeOption,
    };
  },
});
</script>

<style lang="scss" scoped>
.ListFilter {
  display: flex;
  align-items: flex-end;
}

.FilterItem {
  width: 150px;
  display: inline-flex;
  flex-direction: column;

  & + & {
    margin-left: 6px;
  }

  & > label {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 8px;
  }

  & > input {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    background: #ffffff;
    border: 1px solid #dddddd;
    padding: 0 16px;
    height: 40px;
    background: url('../../../assets/ico_selectbar_arrow.png') no-repeat;
    background-size: 10px;
    background-position: calc(100% - 14px) center;

    &.isNotSelected {
      color: #999;
    }
  }

  & > input {
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  & > select {
    &.isNotSelected {
      color: #999;
    }
  }
}

.Filter__applyButton {
  width: 110px;
  text-align: left;
  margin-left: 6px;
  font-size: 14px;
  height: 40px;
  padding-left: 44px;
  background-size: 28px;
  background-image: url('../../../assets/ico_filter_default.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  box-sizing: border-box;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #ff6363;
    background-image: url('../../../assets/ico_filter_hover.svg');
  }

  &.isApplied {
    background-color: #ff6363;
    background-image: url('../../../assets/ico_filter_applied.svg');
    color: #fff;
  }
}

.Filter__textInput {
  background-image: url('../../../assets/ico_Search.svg');
  background-repeat: no-repeat;
  background-position: 14px center;
  &::placeholder {
    color: #888;
  }
}
</style>
