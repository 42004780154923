/**
 * catch 블럭에서 리스펀스 객체에서 에러 메시지를 가져온다.
 * response.data.error 필드에 문자열 형태로 전달된다.
 */
export default function getServerErrorMessage(error: any) {
  const message = error?.response?.data?.message;
  if (!message) {
    return '오류가 발생했습니다.';
  }
  return typeof message === 'string'
    ? message
    : typeof message === 'object'
    ? Object.keys(message).reduce((str, key) => `${str}${message[key]}\n`, '')
    : '오류가 발생했습니다.';
}
