
import datePattern from '@/constants/datePattern';
import { defineComponent, onMounted, watch, reactive, ref, toRef } from 'vue';
import { useRoute } from 'vue-router';
import { format } from 'date-fns/esm';
import ListFilter from './ListFilter.vue';
import ListTable from './ListTable.vue';
import Container from '@/components/Container.vue';
import Pagination from '@/components/Pagination/index.vue';
import router from '@/router';
import partnerAPI from '@/service/partnerAPI';
import convertNumWithComma from '@/utils/convertNumWithComma';

export default defineComponent({
  name: 'SettlementIndex',
  components: {
    ListFilter,
    ListTable,
    Container,
    Pagination,
  },
  setup() {
    const route = useRoute();
    const query = route.query;

    const filterParams = reactive<any>({
      start: (query.start as string) || '',
      end: (query.end as string) || '',
      categoryDepth1Id: Number(query.categoryDepth1Id) || undefined,
      orderType: query.orderType || '',
      settlementStatus: query.settlementStatus || '',
      enableBid: query.enableBid === 'true' ? true : query.enableBid === 'false' ? false : undefined,
      keyword: (query.keyword as string) || '',
      page: Number(query.page) || 0,
      pageSize: Number(query.pageSize) || 10,
      sortBy: (query.sortBy as string) || undefined
    });

    const isFilterOn = ref(query.isFilter === 'true');

    // 정산 목록
    const list = ref({
      success: true,
      data: [],
      message: '',
      total: 0,
    });

    const summaryData = ref({});

    const toggleApplyFilters = () => {
      isFilterOn.value = !isFilterOn.value;
      executeSearch();
    };

    const handleChangeFilterParams = (key: string, value: any) => {
      filterParams[key] = value;
    };

    const handleChangePage = (page: number) => {
      filterParams.page = page - 1;
      executeSearch();
    };

    const handleChangePageSize = (pageSize: number) => {
      filterParams.page = 0;
      filterParams.pageSize = pageSize;
      executeSearch();
    };

    const handleClickSearch = () => {
      console.log('베르나');
      filterParams.page = 0;
      executeSearch();
    };

    const handleChangeSort = (sortBy) => {
      filterParams.sortBy = sortBy
      executeSearch()
    }

    /**
     * 검색 실행
     */
    const executeSearch = () => {
      //fetchAdjustmentLists();
      router.push({
        path: window.location.pathname,
        query: {
          start: filterParams.start || '',
          end: filterParams.end || '',
          categoryDepth1Id: filterParams.categoryDepth1Id || '',
          orderType: filterParams.orderType || '',
          enableBid: String(filterParams.enableBid) || '',
          settlementStatus: filterParams.settlementStatus || '',
          keyword: filterParams.keyword || '',
          page: filterParams.page || 0,
          pageSize: filterParams.pageSize || 10,
          isFilter: String(isFilterOn.value),
          sortBy: String(filterParams.sortBy) || ''
        },
      });
    };

    const getAppliedFilterParams = () =>
      isFilterOn.value
        ? {
            ...filterParams,
            start: filterParams.start
              ? format(
                  new Date(filterParams.start),
                  datePattern.INPUT_DATE + ' 00:00:00'
                )
              : undefined,
            end: filterParams.end
              ? format(
                  new Date(filterParams.end),
                  datePattern.INPUT_DATE + ' 23:59:59'
                )
              : undefined,
          }
        : {
            // 필터가 적용되어있지 않으면 타이틀만 사용한다
            keyword: filterParams.keyword,
            sortBy: filterParams.sortBy,
            page: filterParams.page,
            pageSize: filterParams.pageSize,
          };

    const fetchSettlementLists = async () => {
      console.log('finalFilterParams', getAppliedFilterParams());
      try {
        const { data } = await partnerAPI.partnerOrder.listUsingGET2(
          getAppliedFilterParams()
        );
        list.value = data as any;

        const { data: summary } = await partnerAPI.partnerOrder.summaryUsingGET(
          getAppliedFilterParams()
        );
        summaryData.value = (summary as any).data;
      } catch ({ response }) {
        console.error(response.data);
        alert(response.data.message);
      }
    };

    onMounted(fetchSettlementLists);

    const startRef = toRef(filterParams, 'start');
    const endRef = toRef(filterParams, 'end');
    const categoryIdRef = toRef(filterParams, 'categoryDepth1Id');
    const orderTypeRef = toRef(filterParams, 'orderTypeRef');
    const enableBidRef = toRef(filterParams, 'enableBid');
    const settlementStatusRef = toRef(filterParams, 'settlementStatus');

    watch(
      [isFilterOn, startRef, endRef, categoryIdRef, settlementStatusRef, orderTypeRef, enableBidRef],
      ([isFilterOn]) => {
        // 필터 활성화 상태에서 watch하는 파라미터가 업데이트되면 검색 실행
        if (isFilterOn) {
          filterParams.page = 0;
          executeSearch();
        }
      }
    );

    watch([filterParams, isFilterOn], (newFilter, newIsFilterOn) => {
      console.log('watch filter', newFilter, newIsFilterOn);
      fetchSettlementLists();
    });

    return {
      filterParams,
      list,
      summaryData,
      toggleApplyFilters,
      handleChangePage,
      handleChangePageSize,
      handleChangeSort,
      executeSearch,
      handleClickSearch,
      isFilterOn,
      handleChangeFilterParams,
      fetchSettlementLists,
      convertNumWithComma,
    };
  },
});
